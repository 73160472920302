.grid-container {
    display: list-item;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
.grid-child {
    padding-bottom: 20pt;
}
.input-box {
    border-width: 1pt;
    background-color: blanchedalmond;
    border-radius: 3pt;
    border-color: gray;
    padding-inline: 4pt;
    
}
.PhoneInputInput
{
    border: 2;
    max-width: 150pt !important;

}
.MsoNormalTable
{
    background-color: white !important;
    width: 520px !important;
    max-width: 100% !important;
}
.signature__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 520px;
    max-width: 100%;
    padding-top: 10pt;
    border-top: #3B3B3B;
    border-top-width: 5pt;
    border-top-style: groove;
}
button {
    font-family: "Roboto", Arial, sans-serif;
    color: #3B3B3B;
    text-transform: none;
    font-weight: 400;
    font-style: normal;
    background-color: #FFFFFF;
    line-height: 1;
    letter-spacing: 0px;
    border: 1px solid #3B3B3B;
    padding: 10px;
    border-radius: 0;
    cursor: pointer;
}

body {
    font-family: "Roboto", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.55;
    overflow-x: hidden;
    padding: 20px 10;
    background: #EDEDED;

    display: flex;
    justify-content: center;
    align-items: center;
}
.wrapper {
    overflow-x: scroll;
    width: 400pt;
}